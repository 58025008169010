<template>
  <div>
    <div class="select-viewgroup">
      <div class="select-label mt-2">Mostrando actualmente:</div>
      <div class="select-view">
        <b-form-select v-model="selected_viewgroup">
          <b-form-select-option :value="1"
            >Permisos en Malla Curricular
          </b-form-select-option></b-form-select
        >
      </div>
    </div>
    <b-table small :fields="fields" :items="items" responsive striped>
      <template #cell(name)="data">
        <b class="text-info">{{ data.value }}</b>
      </template>
      <template #cell(campus)="data">
        <CrudIcons
          :current_field="data.field.label"
          :data_value="data.value"
          :data_index="data.index"
        ></CrudIcons>
      </template>
      <template #cell(faculty)="data">
        <CrudIcons
          :current_field="data.field.label"
          :data_value="data.value"
          :data_index="data.index"
        ></CrudIcons>
      </template>
      <template #cell(career)="data">
        <CrudIcons
          :current_field="data.field.label"
          :data_value="data.value"
          :data_index="data.index"
        ></CrudIcons>
      </template>
      <template #cell(matter)="data">
        <CrudIcons
          :current_field="data.field.label"
          :data_value="data.value"
          :data_index="data.index"
        ></CrudIcons>
      </template>
      <template #cell(section)="data">
        <CrudIcons
          :current_field="data.field.label"
          :data_value="data.value"
          :data_index="data.index"
        ></CrudIcons>
      </template>
      <template #cell(evaluation)="data">
        <CrudIcons
          :current_field="data.field.label"
          :data_value="data.value"
          :data_index="data.index"
        ></CrudIcons>
      </template>
      <template #cell(curricular)="data">
        <CrudIcons
          :current_field="data.field.label"
          :data_value="data.value"
          :data_index="data.index"
        ></CrudIcons>
      </template>
      <template #cell(methodological_resources)="data">
        <CrudIcons
          :current_field="data.field.label"
          :data_value="data.value"
          :data_index="data.index"
        ></CrudIcons>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "RoleAccessTable",
  components: {
    CrudIcons: () => import("./CrudIcons"),
  },
  data() {
    return {
      selected_viewgroup: 1,
      items: [
        {
          name: "Directivo Académicos de la Universidad",
          campus: "crud",
          faculty: "crud",
          career: "crud",
          matter: "crud",
          section: "crud",
          evaluation: "crud",
          curricular: "crud",
          methodological_resources: "crud",
        },
        {
          name: "Directivo Académico de la Sede",
          campus: "ru",
          faculty: "cru",
          career: "cru",
          matter: "r",
          section: "r",
          evaluation: "r",
          curricular: "r",
          methodological_resources: "crud",
        },
        {
          name: "Decano Facultad",
          campus: "r",
          faculty: "ru",
          career: "cru",
          matter: "r",
          section: "r",
          evaluation: "r",
          curricular: "r",
          methodological_resources: "cru",
        },
        {
          name: "Directivo de la carrera a nivel institucional",
          campus: "r",
          faculty: "r",
          career: "ru",
          matter: "r",
          section: "r",
          evaluation: "r",
          curricular: "r",
          methodological_resources: "cru",
        },
        {
          name: "Directivo de la carrera sede",
          campus: "r",
          faculty: "r",
          career: "ru",
          matter: "r",
          section: "r",
          evaluation: "r",
          curricular: "r",
          methodological_resources: "cru",
        },
        {
          name: "Docente",
          campus: "r",
          faculty: "r",
          career: "r",
          matter: "r",
          section: "r",
          evaluation: "cr",
          curricular: "r",
          methodological_resources: "r",
        },
      ],
    };
  },
  computed: {
    fields() {
      return [
        { key: "name", label: "Grupo" },
        {
          key: "campus",
          label: String(this.$getVisibleNames("mesh.campus", true, "Sedes")),
        },
        {
          key: "faculty",
          label: String(
            this.$getVisibleNames("mesh.faculty", true, "Facultades")
          ),
        },
        { key: "career", label: "Carreras" },
        // { key: "career", label:  String(this.$getVisibleNames("mesh.career", true)) },
        {
          key: "matter",
          label: this.$getVisibleNames(
            "mesh.egressprofilematter",
            true,
            "Asignaturas"
          ),
        },
        {
          key: "section",
          label: this.$getVisibleNames("teaching.section", true, "Secciones"),
        },
        { key: "evaluation", label: "Evaluaciones" },
        { key: "curricular", label: "Elementos Curriculares" },
        { key: "methodological_resources", label: "Elementos Metodológicos" },
      ];
    },
  },
};
</script>

<style scoped>
td .b-icon {
  cursor: help;
}
.select-viewgroup {
  display: flex;
  margin-bottom: 1%;
}
.select-view {
  width: 50%;
}
.select-label {
  width: 25%;
}
</style>